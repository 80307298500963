/* ROOT */
:root {
  --color-orange: #d9544f;
  --color-orange-light: #ff3c007e;
  --color-black: #1a1a1a;
  --color-body: #55595c;
  --box-shadow: 1px 3px 3px 1px #b1b1b12c;
}

/* CSS RESET */
main {
  min-height: 80vh;
  padding: 3rem;
}

/* Custom CSS */
/* ---------- */

/* Global */
.link_hover a {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.link_hover a:hover {
  background-color: var(--color-orange-light);
  text-decoration: underline;
}
.text-orange {
  color: var(--color-orange);
}
.img-overlay {
  color: #1a1a1a;
}

/* NavBar */
.container {
  flex-direction: row-reverse;
}
.nav-item {
  margin-left: auto;
  cursor: pointer;
}

/* Lists */
.list_style {
  list-style: none;
  list-style-type: '»';
  list-style-position: outside;
  padding-left: 1ch;
}
.list_style li {
  padding-left: 1rem;
}

/* Landing Page */
.landing-page {
  min-height: 81vh;
  position: relative;
}
.profile-img__wrapper {
  width: 12rem;
  height: 12rem;

  overflow: hidden;
  position: relative;
  border-radius: 50%;
}
.profile-img {
  position: absolute;
  width: 120%;
  left: -16px;
}
.scroll-down-btn__container {
  position: absolute;
  right: 0;
}
.scroll-down-btn {
  font-size: 2.2rem;
  color: var(--color-black);
  cursor: pointer;

  animation-name: bounce;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
@keyframes bounce {
  from {
    transform: translateY(2.5px) scale(1);
  }
  to {
    transform: translateY(-5px) scale(1);
  }
}

/* Project Card */
.project-card__container {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: var(--box-shadow);
}
.project__image-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95%;
}
.project__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  box-shadow: var(--box-shadow);

  max-width: 40rem;
  max-height: 40rem;
}
.project__image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  cursor: pointer;
  opacity: 0.95;
  transition: 0.2s ease-in-out;
}
.project__image:hover {
  opacity: 1;
  transform: scale(1.02);
}
.project__btn-wrapper {
  display: flex;
  align-items: center;
}
.project__btn-wrapper h6 {
  margin-top: 9px;
  margin-left: 1px;
}
.project__description p {
  color: var(--color-black);
}
.project__description span {
  color: var(--color-orange);
}
.project__details-container {
  list-style: none;
  margin: 0;
  padding: 0;
}
.project__details-wrapper {
  justify-content: space-between;
}
.project__details-title {
  width: 10.1rem;
}
.project__details-content {
  flex: auto;
}

/* Social */
.social_icon_box {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  width: 6rem;
  height: 6rem;

  transition: all 0.15s ease-in-out;
}
.social_icon_box:hover {
  transform: scale(1.02);
}
.social_icon {
  font-size: 2.7rem;

  color: #fff;

  transition: all 0.2s ease-in-out;
}
.social_icon_box:hover > .social_icon {
  transform: scale(1.1);
}
.social_links_style a {
  text-decoration: none;
}
.social_links_style p {
  font-weight: bold;
  margin-top: 1rem;
}
.social_links_style small {
  font-weight: lighter;
  color: #55595c;
  font-size: smaller;
}

/* local fonts */
@font-face {
  font-family: 'JustinFont';
  src: local('JustinFont'), url(./assets/JustinFont.otf);
}

/* transition timing */
/*   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
 */
